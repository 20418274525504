.text-light-brown {
    color: #bc9a72;
}

.content-title {
    font-size: 18px;
}

.content-width {
    width: 250px;
}

.dotted-line-top {    
    border-top: 2px dotted #bc9a72;
}

.contact-content-background{
    background-image: url('./images/PaperBackground_Middle.png');
    max-width: 626px;
    width: 100%;
    height: auto;
    position: relative;
}

.history-image {
  height: 216px;
  object-fit: contain;
  width: 313px;
}

.no-wrap {
  white-space: nowrap;
}

.dotted-line-about {
  border-top: 2px dotted #d2d1d1;
  margin-top: 10px;
  padding-top: 10px;   
}

.white-link, .white-link:visited, .white-link:focus, .white-link:hover, .white-link:active {
  color: #d2d1d1;
  text-decoration: none;
}

.dotted-line-about-adjusted {
  padding-bottom: 0px;
}

.small-text-link {
  font-size: 0.9em;
}

.text-extra-small {
  font-size: 0.90em;
  padding-top: 2px;
}

.pt-custom {
  padding-top: 2.25rem;
}

.padding-rt {
  padding-right: 20px !important;
}

.cont-width {
  width: 50px !important;
}

@media (max-width: 991px) {
  .no-wrap {
    white-space: normal !important;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-light-brown {
    color: #bc9a72;
}

.content-title {
    font-size: 18px;
}

.content-width {
    width: 250px;
}

.dotted-line-top {    
    border-top: 2px dotted #bc9a72;
}

.dotted-line-right {
    border-right: 2px dotted #bc9a72; 
}

.dotted-line-bottom {
    border-bottom: 2px dotted #bc9a72; 
}

.contact-content-background{
    background-image: url('./images/PaperBackground_Middle.png');
    max-width: 626px;
    width: 100%;
    height: auto;
    position: relative;
}

.text-extra-small {
  font-size: 0.90em;
  padding-top: 2px;
}

.pt-custom {
  padding-top: 2.25rem;
}

.padding-rt {
  padding-right: 20px !important;
}

.cont-width {
  width: 50px !important;
}